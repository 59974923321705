<template>
  <div class="cemetery-details-page">
    <div class="not-fount readable_content" v-if="cemeteryNotFound">
      <h5>Cemetery Not Found!</h5>
      <p>Sorry, we couldn't find the page at this address.</p>
    </div>
    <div class="limited_content" v-else-if="!cemeteryState || cemeteryLoadingState">
      <mcr-loading-indicator :loading="true"></mcr-loading-indicator>
    </div>
    <div class="limited_content details-content" v-else>
      <div class="desktop-photos" v-if="cemeterySource && cemeterySource.cover">
        <card-with-ribbon text="View images" @click.native="goToViewer">
          <img class="preview-image" :src="cemeterySource.cover.url" />
        </card-with-ribbon>
      </div>

      <div class="details">
        <div class="header">
          <h4>{{ cemeteryState.name }}</h4>
          <hr />
        </div>

        <div class="mobile-photos" v-if="cemeterySource && cemeterySource.cover">
          <card-with-ribbon text="View images" @click.native="goToViewer">
            <img class="preview-image" :src="cemeterySource.cover.url" />
          </card-with-ribbon>
        </div>

        <div class="content-container sub-section">
          <div v-html="cemeteryState.desc" v-if="cemeteryState.desc"></div>
        </div>
        <div v-if="cemeteryState.website">
          <strong>Website:</strong> <a :href="cemeteryState.website" target="_blank">{{ cemeteryState.website }}</a>
        </div>
        <div v-if="cemeteryState.associations.length">
          <strong>Administered by:</strong> {{ cemeteryState.associations[0].name }}
        </div>
        <div v-if="cemeterySource && cemeterySource.images_count">
          <strong>Tombstone Images available:</strong> {{ cemeterySource.images_count }}
        </div>
        <div><strong>Total persons indexed at this cemetery:</strong> {{ cemeteryState.burial_records_count }}</div>

        <section-search-records
          v-if="cemeteryState.burial_records_count"
          :cemetery-name="cemeteryState.name"
          @submit="searchBurialRecords"
        ></section-search-records>
        <router-link v-if="cemeteryState.burial_records_count" class="sub-section browse-all" :to="searchAllRoute"
          >Browse all burial records in this cemetery</router-link
        >

        <google-map-location-point
          v-if="cemeteryState.location.lng || cemeteryState.location.lat"
          :lng="cemeteryState.location.lng"
          :lat="cemeteryState.location.lat"
          class="section"
        ></google-map-location-point>
        <div class="place-address">
          <detail-item :label="place.level_name" v-for="place in cemeteryState.place_details" :key="place.id">
            <router-link :to="getPlaceRoute(place.id, place.pinyin)" rel="nofollow">
              {{ place.pinyin }}
            </router-link>
          </detail-item>
          <detail-item label="Address">{{ cemeteryState.address }}</detail-item>
          <detail-item label="Surnames" v-if="cemeterySource && cemeterySource.clans && cemeterySource.clans.length">
            <router-link
              :to="getSurnameRoute(name.name_hant || name.name_ch)"
              class="name"
              v-for="name in cemeterySource.clans"
              :key="name.name_ch"
              rel="nofollow"
              >{{ name.name_hant || name.name_ch }}</router-link
            >
          </detail-item>
          <detail-item
            label="Ancestral Place"
            v-if="cemeterySource.ancestral_places && cemeterySource.ancestral_places.length"
          >
            <router-link
              v-for="place in cemeterySource.ancestral_places"
              :key="place.id"
              :to="getPlaceRoute(place.id, place.pinyin)"
              class="name"
              rel="nofollow"
              >{{ place.pinyin }}</router-link
            >
          </detail-item>
        </div>
        <p class="supplemental" v-if="cemeterySource.data_provider">
          Source made available courtesy of
          <a :href="cemeterySource.data_provider_url" target="_blank" rel="nofollow">
            {{ cemeterySource.data_provider }}
          </a>
        </p>
        <div class="section" v-if="relatedSources.length">
          <h5>Sources Associated with this Cemetery</h5>
          <ul>
            <li v-for="source in relatedSources">
              <router-link :to="{name: 'source-detail', params: {sourceId: source.id}}" rel="nofollow">
                {{ getSourceTitleDisplay(source) }}
              </router-link>
              <span> ({{ source.type_display }})</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CardWithRibbon from '@common/elements/layouts/CardWithRibbon';
import detailItem from '@common/elements/layouts/detailsItem';
import GoogleMapLocationPoint from '@common/elements/maps/GoogleMapLocationPoint';
import SectionSearchRecords from '@common/pages/cemetery/SectionSearchRecords';
import {sourceTypes} from '@common/utils/consts.source';
import {getPlaceDetailRoute} from '@common/utils/utils.routes';
import {mapGetters} from 'vuex';

export default {
  metaInfo() {
    if (!this.cemeteryState) {
      return {};
    }
    const place = this.cemeteryState.place_details
      ? this.cemeteryState.place_details
          .map(p => p.pinyin)
          .reduceRight((prevValue, currValue) => (prevValue ? `${prevValue}, ${currValue}` : currValue), '')
      : '';
    const description = `The ${this.cemeteryState.name} is located in ${place}. Search ${this.cemeteryState.burial_records_count} burial records from the ${this.cemeteryState.name}.`;
    return {
      title: `The ${this.cemeteryState.name}`,
      meta: [{vmid: 'description', name: 'description', content: description}],
    };
  },
  created() {
    this.$store.dispatch('fetchCemeteryDetailsAction', this.$route.params.id).catch(() => {
      this.cemeteryNotFound = true;
    });
  },
  data() {
    return {
      cemeteryNotFound: false,
    };
  },
  computed: {
    ...mapGetters(['cemeteryState', 'cemeteryLoadingState']),
    cemeterySource() {
      return this.cemeteryState.sources.find(s => s.type === sourceTypes.CEMETERY) || {};
    },
    relatedSources() {
      return this.cemeteryState.sources.filter(s => s.type !== sourceTypes.CEMETERY);
    },
    searchAllRoute() {
      return {name: 'search-burial-records', query: {cemetery_id: this.$route.params.id}};
    },
  },
  methods: {
    searchBurialRecords({firstName = '', lastName = ''}) {
      let query = {first_name: firstName, surname: lastName, cemetery_id: this.$route.params.id};
      return this.$router.push({name: 'search-burial-records', query});
    },
    getSurnameRoute(word) {
      return {name: 'search-tool-surname-detail', query: {word}};
    },
    getPlaceRoute(id, name) {
      return getPlaceDetailRoute(id, name);
    },
    goToViewer() {
      const sourceId = this.cemeterySource.id;
      const image = this.cemeterySource.cover;
      this.$router.push({name: 'source-viewer', params: {sourceId}, query: {page: image.priority, page_id: image.id}});
    },
    getSourceTitleDisplay(source) {
      const {publication_year, time_period_start, time_period_end} = source;
      const years =
        publication_year ||
        (time_period_start || time_period_end ? `${time_period_start || '...'}-${time_period_end || '...'}` : '');
      const title = source.title_en || source.title_ch;
      return years ? `${title}, ${years}` : title;
    },
  },
  components: {CardWithRibbon, SectionSearchRecords, detailItem, GoogleMapLocationPoint},
};
</script>

<style lang="scss" scoped>
.cemetery-details-page {
  min-height: 500px;

  .details-content {
    display: flex;
    flex-direction: row;

    .desktop-photos {
      margin-right: 48px;
      width: 40%;
      min-width: 280px;
      flex-shrink: 0;
      position: relative;

      .card-with-ribbon {
        min-width: 100%;
      }
    }

    .mobile-photos {
      display: none;
    }

    .details {
      flex-grow: 1;
    }
  }

  .content-container {
    text-align: justify;
  }
}

.sub-section {
  margin-top: 18px;
  display: block;
}

.section {
  margin-top: 50px;
}

a.browse-all + .place-address {
  margin-top: 50px;
}

.place-address .details-item {
  margin-top: 0;
}

.place-address .name:not(:last-child)::after {
  content: ', ';
}

@media only screen and (max-width: $breakpoint-tablet) {
  .cemetery-details-page {
    .details-content {
      flex-direction: column;

      .desktop-photos {
        display: none;
      }

      .mobile-photos {
        display: block;
        margin: 30px 0;
        .card-with-ribbon {
          margin: auto;
        }
      }
    }
  }
}
</style>
